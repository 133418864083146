import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { PersonHttpApi } from '../api/PersonHttpApi';
import { Source } from '../../type/Sources';

export const useSources = (
  personId?: string,
  queryOptions?: Omit<UseQueryOptions<Source[], Error, Source[]>, 'queryKey'>
): UseQueryResult<Source[], Error> => {
  return useQuery({
    ...queryOptions,
    queryKey: ['person', 'sources', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.listSources(personId);
    },
    enabled: personId !== undefined,
  });
};
