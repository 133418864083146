import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { PersonHttpApi } from '../api/PersonHttpApi';

export const useGiftSchedules = (
  personId?: string,
  queryOptions?: Omit<UseQueryOptions<any[], Error, any[]>, 'queryKey'>
): UseQueryResult<any[], Error> => {
  return useQuery({
    ...queryOptions,
    queryKey: ['person', 'giftSchedules', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.listGiftSchedules(personId);
    },
    enabled: personId !== undefined,
  });
};
