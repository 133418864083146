import { ButtonPrimary, InputText, Label } from '@foyyay/control-elements';
import { isEmpty as _isEmpty } from 'lodash';
import _pick from 'lodash/pick';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '../../../component/Layout';
import { LoadingSpinnerBlock } from '../../../component/LoadingSpinner';
import { useChurches } from '../../../hooks/useChurches';
import { useLocationEffect } from '../../../hooks/useLocationEffect';
import { FORMAT_HINTS } from '../../../lib/objectGridAttributeLibrary';
import { ChurchesList } from '../component/ChurchesList';

const FILTER_KEYS = Object.freeze(['name', 'id', 'phone', 'address_email', 'address_website']);

export const Search = () => {
  const location = useLocation();
  const history = useNavigate();

  const { data, isLoading } = useChurches();

  const q = (new URLSearchParams(location.search).get('q') || '').trim();
  const [query, setQuery] = useState(q);

  const churches = React.useMemo(() => {
    let filter = q;
    let keys = [...FILTER_KEYS];
    if (filter === undefined || filter.length < 1) {
      return _reverse(_sortBy(data, 'created_at'));
    }

    filter = filter.toLowerCase();

    return (
      data?.filter((church) =>
        JSON.stringify(Object.values(_pick(church, keys)))
          .toLowerCase()
          .includes(filter)
      ) ?? []
    );
  }, [data, q]);

  const onSearchSubmit = useCallback(
    (event) => {
      event.preventDefault();
      history(`/churches/search?q=${query}`);
    },
    [history, query]
  );

  // Listen for history change and dispatch search like location changes.
  useLocationEffect(() => {
    decodeURIComponent((new URLSearchParams(location.search).get('q') || '').trim());
  });

  const onInputChange = useCallback(
    (value: string) => {
      setQuery(value);
    },
    [setQuery]
  );

  const searching = q.length > 0;

  let headline = 'All Churches';
  let statement = 'Newest to Oldest';
  if (searching === true) {
    headline = 'Search Results';
    statement = `${churches.length} results for "${q}"`;
  }
  if (isLoading === true) {
    statement = 'Loading...';
  }

  return (
    <>
      <Layout.Panel>
        <form onSubmit={onSearchSubmit}>
          <Label> Enter Church id, name, owner email, phone or website</Label>
          <InputText name="q" value={decodeURIComponent(query)} onChange={onInputChange} />
          <ButtonPrimary>Go</ButtonPrimary>
        </form>
      </Layout.Panel>

      {isLoading === true ? (
        <LoadingSpinnerBlock />
      ) : (
        <>
          {_isEmpty(q) === false && (
            <ChurchesList
              headline={headline}
              statement={statement}
              data={churches}
              keys={FILTER_KEYS}
              rowKey={'id'}
              hints={{
                name: FORMAT_HINTS.NONE,
                email: FORMAT_HINTS.NONE,
                website: FORMAT_HINTS.NONE,
              }}
            />
          )}
        </>
      )}
    </>
  );
};
