import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ChurchHttpApi, ChurchListResponse } from '../api/ChurchHttpApi';

export const useChurches = (
  queryOptions?: UseQueryOptions<ChurchListResponse, Error, ChurchListResponse>
): UseQueryResult<ChurchListResponse, Error> => {
  return useQuery({
    ...queryOptions,
    queryKey: ['churches'],
    queryFn: async () => await ChurchHttpApi.list(),
  });
};
