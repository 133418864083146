import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { PersonHttpApi } from '../api/PersonHttpApi';

export const useTransactions = (
  personId?: string,
  queryOptions?: Omit<UseQueryOptions<any[], Error, any[]>, 'queryKey'>
): UseQueryResult<any[], Error> => {
  return useQuery({
    ...queryOptions,
    queryKey: ['person', 'transactions', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      const response = await PersonHttpApi.listTransactions(personId);
      return response.rows;
    },
    enabled: personId !== undefined,
  });
};
