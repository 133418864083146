import { QueryOptions, useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Church } from '../../type/Church';
import { Merchant } from '../../type/Merchant';
import { MerchantHttpApi } from '../api/MerchantHttpApi';

const getCacheKey = (churchId?: string) => ['merchants', churchId];

export const useMerchants = (
  churchId?: string,
  queryOptions?: QueryOptions<Merchant[], Error, Merchant[]>
): UseQueryResult<Merchant[], Error> => {
  return useQuery({
    ...queryOptions,
    queryKey: getCacheKey(churchId),
    queryFn: async () => {
      if (churchId === undefined) {
        throw new Error('Church ID is required');
      }
      return await MerchantHttpApi.listMerchants({ id: churchId } as Church);
    },
    enabled: churchId !== undefined,
  });
};

export const useUnBoardedMerchants = () => {
  return useQuery({
    queryKey: ['unBoarded'],
    queryFn: async () => await MerchantHttpApi.listUnBoarded(),
  });
};

export const useMerchantActivation = (church: Church, merchant: Merchant) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (shouldActivate: boolean) => {
      if (shouldActivate) {
        await MerchantHttpApi.activateMerchant(church, merchant);
      } else {
        await MerchantHttpApi.deactivateMerchant(church, merchant);
      }
    },
    onSuccess: (data) => queryClient.setQueryData(getCacheKey(church.id), data),
  });
};

export const useRefreshMerchant = (church: Church, merchant: Merchant) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => await MerchantHttpApi.refreshMerchant(church, merchant),
    onSuccess: (data) => queryClient.invalidateQueries({ queryKey: getCacheKey(church.id) }),
  });
};
