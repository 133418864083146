import { useAdmins } from './useAdmins';
import { useChurch } from './useChurch';
import { useMakeoverCustomProcess } from './useMakeoverCustomProcess';
import { useMerchants } from './useMerchant';
import { usePersons } from './usePerson';
import { useRpMerchants } from './useRpMerchant';
import { useSiteEngines } from './useSiteEngines';
import { useSubscriptions } from './useSubscription';

/**
 * Prefetches all the data needed for a church page.
 */
export const usePrefetchChurchData = (churchId?: string) => {
  const query = useChurch(churchId);

  const { data: church } = query;
  const { data: merchants = [] } = useMerchants(churchId);
  const rpMerchantIds = merchants.map((merchant) => merchant.rebelpay_merchant_id).filter((id) => id !== undefined);
  useRpMerchants(rpMerchantIds);
  const { data: admins = [] } = useAdmins(church);
  useSiteEngines(churchId);
  usePersons(admins.map((admin) => admin.id));
  useSubscriptions(churchId);
  useMakeoverCustomProcess({ id: churchId });

  return query;
};
