import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { App } from './App';
import { AWS_AMPLIFY_CONFIG, BUILD_COMMIT_REF, SENTRY_ENVIRONMENT, SENTRY_IO_DSN } from './constant';
import './index.css';
import { createIDBPersister } from './lib/createIDBPersister';
import { store } from './store/configureStore';

if (['production', 'staging'].includes(SENTRY_ENVIRONMENT)) {
  Sentry.init({ dsn: SENTRY_IO_DSN, environment: SENTRY_ENVIRONMENT, release: BUILD_COMMIT_REF });
}

Auth.configure(AWS_AMPLIFY_CONFIG);

const appStore = store;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const persister = createIDBPersister();

const renderApp = () => {
  const container = document.getElementById('root') ?? new DocumentFragment();
  const root = createRoot(container);

  return root.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ReduxProvider store={appStore}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </PersistQueryClientProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();
