import { UseQueryOptions, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Person } from '../../type/Person';
import { ConnectionsResponse, PersonHttpApi } from '../api/PersonHttpApi';

const getCacheKey = (personId?: string) => ['person', personId];

export const usePerson = (
  personId?: string,
  queryOptions?: Omit<UseQueryOptions<Person | undefined, Error, Person | undefined>, 'queryKey'>
): UseQueryResult<Person | undefined, Error> => {
  return useQuery({
    ...queryOptions,
    queryKey: getCacheKey(personId),
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.read(personId);
    },
    enabled: personId !== undefined,
  });
};

export const usePersons = (personIds: string[]) => {
  return useQueries({
    queries: personIds.map((personId) => ({
      queryKey: getCacheKey(personId),
      queryFn: async () => await PersonHttpApi.read(personId),
    })),
  });
};

export const usePersonConnections = (
  personId?: string,
  queryOptions?: Omit<UseQueryOptions<ConnectionsResponse, Error, ConnectionsResponse>, 'queryKey'>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: ['person', 'connections', personId],
    queryFn: async () => {
      if (personId === undefined) {
        throw new Error('personId is required');
      }
      return await PersonHttpApi.connections(personId);
    },
    enabled: personId !== undefined,
  });
};
