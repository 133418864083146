import Auth from '@aws-amplify/auth';
import _pick from 'lodash/pick';

const MESSAGE_ATTRIBUTES = Object.freeze(['status', 'statusText', 'message']);

export async function apiCall(method, root, path, body, options = {}) {
  const currentSession = await Auth.currentSession();

  const fetchOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: currentSession.idToken.jwtToken,
    },
  };

  return richFetch(method, root, path, body, fetchOptions);
}

export async function richFetch(method, root, path, body, options = {}) {
  let { parseResponse, ...fetchOptions } = options;
  options = undefined;
  if (parseResponse === undefined) {
    parseResponse = true;
  }

  fetchOptions = {
    method: method,
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
    },
  };

  const url = [root, path].join('');

  if (body !== undefined) {
    fetchOptions.body = JSON.stringify(body);
    fetchOptions.headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(url, fetchOptions);
  // removed try-catch to let calling code handle fail to fetch
  // catching failure to fetch did not yield more information
  // we just have no info when there's an exception to fetch.

  // Return undefined if status is 204 (No Content)
  if (response.status === 204) {
    return undefined;
  }

  if (parseResponse !== true) {
    return response;
  }

  let content;
  let contentType = response.headers.get('Content-Type') || '';
  contentType = contentType.toLowerCase();

  if (contentType.includes('application/json')) {
    content = await response.json();
  } else {
    content = await response.text();
  }

  // if response is not Response, ok will still be falsey
  if (!response.ok) {
    const sourceObject = { ...response, ...content };
    const errorMessage = MESSAGE_ATTRIBUTES.reduce((acc, next) => sourceObject[next] || acc, '');
    const data = _pick(response, MESSAGE_ATTRIBUTES);
    data.body = content;

    const error = Object.assign(new Error(errorMessage), data);
    throw error;
  }

  return content;
}
